<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="
        login login-1
        d-flex
        flex-column flex-lg-row flex-column-fluid
        bg-white
      "
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <!-- <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: white"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              src="../../../../assets/images/login_logo.png"
              class="max-h-100px"
              alt=""
            />
          </a>
          <h3
            class="
              font-weight-bolder
              text-dark text-center
              font-size-h4 font-size-h3-lg
            "
          >
            At Ingomu our mission is to uplift humankind by<br />
            making a positive difference in the lives of many.<br />
            Go make that difference!
          </h3>
        </div>
        <div
          class="
            aside-img
            d-flex
            flex-row-fluid
            bgi-no-repeat bgi-position-y-bottom bgi-position-x-center
          "
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div> -->
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-content-center
          position-relative
          overflow-hidden
          p-7
          mx-auto
        "
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
             <div class="d-flex flex-center mb-15">
							<a href="#">
								<img src="../../../../assets/images/login_logo.png" class="max-h-100px" alt="">
							</a>
						</div>
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="pb-13 pt-lg-0 pt-5 text-center">
                <h3>
                  Coach Login
                </h3>
              </div>
              <div class="form-group">
                <!-- <label class="font-size-h6 font-weight-bolder text-dark fs-15"
                  >Email</label
                > -->
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="
                      form-control 
                      h-auto 
                      form-control-solid 
                      py-4
                      px-8
                    "
                    type="text"
                    name="email"
                    placeholder="Email"
                    ref="email"
                    v-model="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="
                      form-control 
                      h-auto 
                      form-control-solid 
                      py-4
                      px-8
                    "
                    type="password"
                    name="password"
                    ref="password"
                    placeholder="Password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
                <div class="text-right pt-5">
                  <!-- <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Password</label
                  > -->
                  <a
                    class="
                      text-ingomublue
                      text-hover-primary
                      pt-5
                    "
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >Forgot Password ?</a
                  >
                </div>
              </div>
              <div class="pb-lg-0 pb-5 text-center">
                <vue-honeypot ref="honeypot" />
                <button
                  ref="kt_login_signin_submit"
                  class="
                    btn 
                    btn-primary 
                    font-weight-bold 
                    px-9 
                    py-4
                    my-3 
                    mx-4
                  "
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5 text-center">
                <h3>
                  Forgotten Password ?
                </h3>
                <p class="text-dark">
                  Enter your email to reset your password
                </p>
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control 
                      h-auto 
                      form-control-solid 
                      py-4
                      px-8
                  "
                  type="email"
                  placeholder="Email"
                  name="email"
                  ref="email"
                  v-model="form.forgetEmail"
                  autocomplete="off"
                />
              </div>
              <div class="pb-lg-0 pb-5 text-center">
                <button
                  ref="kt_login_forgot_submit"
                  class="
                    btn 
                    btn-primary 
                    font-weight-bold 
                    px-9 
                    py-4
                    my-3 
                    mx-4
                  "
                >
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="
                    btn 
                    btn-primary 
                    font-weight-bold 
                    px-9 
                    py-4
                    my-3 
                    mx-4
                  "
                  @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <div
          class="
            text-center
            justify-content-lg-start justify-content-center
            py-7 
            py-lg-0
            mt-10
          "
        >
          <a href="https://ingomu.com/terms" target="_blank" class="text-ingomublue"
            >Terms</a
          >
          <!-- <a
            href="#"
            class="text-ingomublue ml-10"
            >Contact Us</a
          > -->
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
    <cookie-law theme="dark-lime"></cookie-law>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss" scoped>
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<style>
.text-ingomublue {
  color: #3b5998 !important;
}

.text-ingomugreen {
  color: #46c346 !important;
}
.login.login-1 .login-aside .aside-img {
  min-height: auto;
}
.fv-plugins-message-container {
  display: none !important;
}
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import emailAddress from "@/assets/plugins/formvalidation/dist/es6/validators/emailAddress";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState, mapActions } from "vuex";
import CookieLaw from "vue-cookie-law";
import Treeselect3Vue from "../../plugins/treeselect/Treeselect3.vue";
import VueHoneypot from 'vue-honeypot'

export default {
  name: "login-1",
  components: { 
    CookieLaw,
    VueHoneypot
  },
  metaInfo() {
    return {
      title: "Login",
    };
  },
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      form: {
        email: "",
        password: "",
        forgetEmail: "",
      },
    };
  },
  computed: {
    ...mapState(["errors"]),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return "https://ingomucoachescdn.s3.amazonaws.com/logo_bottom.svg";
    },
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            callback: {
              message: "The value is not a valid email address",
              callback: function (input) {
                const value = input.value;
                if (value === "") {
                  return true;
                }
                return emailAddress().validate({
                  value: value,
                }).valid;
              },
            },
            notEmpty: {
              message: "Email is required",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            callback: {
              message: "The value is not a valid email address",
              callback: function (input) {
                const value = input.value;
                if (value === "") {
                  return true;
                }

                // I want the value has to pass both emailAddress and regexp validators
                return (
                  emailAddress().validate({
                    value: value,
                  }).valid &&
                  formValidation.validators.regexp().validate({
                    value: value,
                    options: {
                      regexp: "^[^@s]+@([^@s]+.)+[^@s]+$",
                    },
                  }).valid
                );
              },
            },
            notEmpty: {
              message: "Email is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv.on("core.form.valid", () => {
      var email = this.form.email;
      var password = this.form.password;

      // clear existing errors
      this.logout();

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        try {
        this.$refs.honeypot.validate()
        this.login({ email, password })
          .then(() => {
            this.$router.push({ name: "dashboard" });
          })
          .catch((error) => {
            alert(error);
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      } catch (error) {
        // error handling
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }
      }, 2000);
    });

    this.fv.on("core.form.invalid", () => {
      alert("Please, provide correct data!");
    });

    this.fv2.on("core.form.valid", () => {
      var email = this.form.forgetEmail;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_forgot_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        this.forgotPassword({ email })
          .then((message) => {
            alert(message);
          })
          .catch((error) => {
            alert(error);
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    });

    this.fv2.on("core.form.invalid", () => {
      alert("Please, provide correct data!");
    });
  },
  methods: {
    ...mapActions(["forgotPassword", "login", "logout"]),
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
  },
};
</script>
